<template>
  <v-card width="100%" max-width="700px" elevation="2" class="pa-0">
    <v-card-title>
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon size="30px" color="primary">mdi-ballot</v-icon>
        </div>
        <div class="vc-text-primary vc-text-bold vc-font-18 ml-2">
          Agendamento
        </div>
      </div>
    </v-card-title>

    <div class="pl-2 pr-2">
      <div class="vc-divider-grey"></div>
    </div>

    <v-card-title class="pt-0 pb-0 mt-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-briefcase-account</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong>Profissional da saúde:</strong>
          {{ currentDoctor.name_doctor }}
        </div>
      </div>
    </v-card-title>

    <v-card-title class="pt-0 pb-0 mt-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-account-hard-hat</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong>Especialidade:</strong> {{ currentSpecialty.name }}
        </div>
      </div>
    </v-card-title>

    <v-card-title class="pt-0 pb-0 mt-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-card-account-details-star</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong>Plano de Saúde:</strong> {{ currentConvenant.name }}
        </div>
        
      </div>
    </v-card-title>
    <v-card-title class="pt-0 pb-0 mt-3 mb-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-calendar-month</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong>Data e hora:</strong> {{ currentSchedule.scheduling }}
        </div>
      </div>
    </v-card-title>
    
    <!-- <v-card-title class="pt-0 pb-0 mt-3 mb-3">
      <div class="vc-flex-center">
        <div class="icon-box vc-flex-center pa-1">
          <v-icon>mdi-account</v-icon>
        </div>
        <div class="vc-font-16 ml-2">
          <strong>Paciente:</strong>
          
          {{
            currentPatient.username
              ? currentPatient.username
              : currentPatient.name
          }}
        </div>
      </div>
    </v-card-title> -->

    <div class="pl-2 pr-2">
      <div class="vc-divider-grey"></div>
    </div>

    <v-card-actions>
      <v-col cols="12" class="pa-2 text-center">
        <v-btn @click="savePatientSchedule" text color="primary">
          <div class="vc-text-bold">confirmar</div>
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import ls from 'local-storage';
export default {
  name: 'card-confirmation',
  data: () => ({
    feedbackDialog: false,
    modelScheduling: {
      patient_id: '',
      schedule_id: '',
      time: '',
      observation: '',
      return_id: '',
      status: '',
      health_insurance_id: '',
      health_insurance_plans_id: '',
      company_id: '',
    },
  }),
  computed: {
    currentScheduleCompany() {
      return this.$store.getters.currentScheduleCompany;
    },
    currentSchedule() {
      return this.$store.getters.currentSchedule;
    },
    currentConvenant() {
      return this.$store.getters.currentConvenant;
    },
    currentSpecialty() {
      return this.$store.getters.currentSpecialty;
    },
    currentDoctor() {
      return this.$store.getters.currentDoctor;
    },
    currentPatient() {
      return this.$store.getters.currentPatient;
    },
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
  },
  methods: {
    savePatientSchedule() {
      if (this.currentPatient.username && ls.get('patientId')) {
        console.log('if')
        this.modelScheduling.patient_id = ls.get('patientId');
      }else{
        console.log('else')
         this.modelScheduling.patient_id = this.currentPatient.id
      }
      this.modelScheduling.schedule_id = this.currentSchedule.schedule.schedule_id;
      this.modelScheduling.time = this.currentSchedule.schedule.time;
      this.modelScheduling.status = 'SCHEDULED';
      this.modelScheduling.health_insurance_id = this.currentConvenant.health_insurance_id;
      this.modelScheduling.health_insurance_plans_id = this.currentConvenant.id;
      this.modelScheduling.company_id = this.currentCompany.id;
      this.modelScheduling.specialit_id = this.currentSpecialty.id;
      this.modelScheduling.doctor_id = this.currentDoctor.doctor_id;
      this.$store
        .dispatch('savePatientSchedule', this.modelScheduling)
        .then(() => {
          this.$store.commit('SET_NOTIFICATION', {
            type: 'success',
            message: 'Agendamento realizado com sucesso!',
          });
          this.$router.push({ name: 'Home' });
        })
        .catch((error) => {
          var messageError = '';
          switch (error.response.data.message) {
            case 'patient is already scheduled on this time':
              messageError = 'O Paciente já está agendado para esse horário';
              break;
            default:
              messageError = 'Não foi possivél realizar agendamento, horário não está mais disponivel!';
          }
          this.$store.commit('SET_NOTIFICATION', {
            type: 'danger',
            message: messageError,
          });
        });
    },
  },
};
</script>
