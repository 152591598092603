<template>
  <v-form ref="formPatient" lazy-validation class="vc-form-scheduling vc-w-100">
    <v-row v-if="user" class="ma-0 vc-w-100">
      <v-alert
        v-if="patients.length > 0"
        color="primary"
        dense
        text
        type="info"
        class="text-center"
      >
        Para <strong>selecionar</strong> o paciente desejado, por favor,
        selecione a opção <strong>'Selecionar'</strong> abaixo.
      </v-alert>

      <v-col cols="12" md="6" class="vc-flex-center pa-2 mt-3 mb-2">
        <card-scheduling
          :id="user.id"
          :title="`${user.username} (Você)`"
          icon="mdi-account"
          @action="setPatient(user)"
        />
      </v-col>
      <v-col cols="12" md="6" class="vc-flex-center pa-2 mt-3 mb-2">
        <v-card
          width="100%"
          max-width="550px"
          min-height="80px"
          elevation="2"
          class="pa-0 vc-flex-center"
          @click="depedentModal = true"
        >
          <v-icon color="primary" left>mdi-account-plus</v-icon>
          Adicionar dependente
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-for="(patient, i) in patients"
        :key="`${i}${patient.name}`"
        class="vc-flex-center pa-2 mt-3 mb-2"
      >
        <card-scheduling
          :id="patient.id"
          :title="`${patient.name} (Dependente)`"
          icon="mdi-account"
          type="patient"
          @action="setPatient(patient)"
        />
      </v-col>
    </v-row>

    <v-row v-else class="ma-0 vc-w-100 vc-flex-center">
      <div class="vc-column-center vc-w-100 pa-3">
        <div class="vc-font-20 vc-text-primary vc-text-bold">
          Informe seus dados
        </div>
        <div class="vc-font-14 vc-text-primary mt-3">
          <v-alert type="info" color="primary" border="left" text class="mb-0">
            Para <strong> Concluir seu agendamento</strong>, por favor, informe
            seus dados!
          </v-alert>
        </div>
        <!-- <div class="vc-font-14 vc-text-primary mt-3">
          <v-alert type="info" color="primary" border="left" text class="mb-0">
            Ao se <strong>cadastrar</strong>, um e-mail será enviado com as <strong>credenciais</strong> necessárias para efetuar o <strong>login!</strong>
          </v-alert>
        </div> -->
        <v-form
          class="vc-w-100 vc-cadaster-form"
          ref="cadasterForm"
          v-model="valid"
          lazy-validation
        >
         
          <v-row
            class="vc-cadaster-form-body pl-5 pr-5 pb-0 pt-0 ma-0 mt-4 vc-w-100"
          >
            <v-col cols="12" class="pa-0 mb-3">
              <div class="ml-3">Nome Completo</div>
              <v-text-field
                dense
                solo
                v-model="modelRegister.people.name"
                :rules="[rules.required]"
                class="vc-cadaster-input"
                placeholder="Digite aqui..."
             
              ></v-text-field>
            </v-col>
       
            <v-col
              cols="12"
              sm="6"
              class="mt-1 vc-cadaster-apply-responsivity-left"
            >
              <div class="ml-3">Telefone principal</div>
              <v-text-field
                dense
                solo
                v-model="modelRegister.people.phone1"
                :rules="[rules.required, rules.minPhone]"
                class="vc-cadaster-input"
                placeholder="Ex: (55)95555-5555"
                v-mask="'(##)#####-####'"
               
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              class="mt-1 vc-cadaster-apply-responsivity-left"
            >
              <div class="ml-3">CPF</div>
              <v-text-field
                dense
                solo
                v-model="modelRegister.people.cpf"
                :rules="[rules.required, rules.minCPF]"
                class="vc-cadaster-input"
                placeholder="Digite aqui..."
                v-mask="'###.###.###-##'"
               
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div class="vc-row-center mt-5">
          <v-btn @click="registerPatient()" :loading="loading" color="primary"
            ><div class="vc-text-bold">Cadastrar</div></v-btn
          >
        </div>
      </div>
    </v-row>
    <v-dialog
      v-if="depedentModal"
      class="auth-dialog-index"
      v-model="depedentModal"
      max-width="450px"
    >
      <form-dependent
        :patient_id="patient_id"
        :company_id="company_id"
        :user_id="user.id"
        @getDependent="getDependent()"
        @closeDialog="closeDependentDialog"
      />
    </v-dialog>
  </v-form>
</template>

<script>
import ls from 'local-storage';
import CardScheduling from '../CardScheduling.vue';
import FormDependent from './FormDependent.vue';

export default {
  name: 'form-patient',
  props: {
    company_id: {
      type: String,
      default: '',
    },
    currentPatientId: {
      type: String,
      default: '',
    },
  },
  components: {
    CardScheduling,
    FormDependent,
  },
  data: () => ({
    patient_id: '',
    depedentModal: false,
    loading:false,
    patients: [],
    rules: {
        required: (value) => !!value || 'Campo obrigatório!',
        min: (v) => v.length >= 8 || 'Mínimo de 6 caracteres',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido';
        },
        minPhone: (v) => v.length >= 14 || 'Mínimo de 11 caracteres!',
        minDate: (v) => v.length >= 10 || 'Data Incompleta!',
        minCPF: (v) => v.length >= 14 || 'Mínimo de 11 caracteres!',
        minCEP: (v) => v.length >= 9 || 'Mínimo de 8 caracteres!',
      },
    catchNewDependent: false,
    modelRegister: {
      people: {
          name: '',
          cpf: '',
          phone1: '',
        },
        company_id: '',
      },
      modelRegisterDefault: {
        people: {
          name: '',
          cpf: '',
          phone1: '',
        },
        company_id: '',
      },
  }),
  watch: {
    currentPatientId(val) {
      if (val) {
        this.getDependent();
      }
    },
    user() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    init() {
      if (this.currentPatientId) {
        console.log(this.currentPatientId);
        this.getDependent();
      }
      // if (!this.user) {
      //   this.$store.commit('SET_AUTH_MODAL', true);
      // }
    },
   
     
    registerPatient() {
      this.showAlert = false;
      if (this.$refs.cadasterForm.validate()) {
        this.loading = true;
        this.modelRegister.company_id = this.currentCompany.id;
        this.modelRegister.people.cpf = this.modelRegister.people.cpf.replaceAll(
        '.',
        ''
      );
      this.modelRegister.people.cpf = this.modelRegister.people.cpf.replace(
        '-',
        ''
      );
     
    
    
        this.$store
          .dispatch('savePatientVCP', this.modelRegister)
          .then((resp) => {
            this.modelRegister = this.modelRegisterDefault;
            this.$store.commit('SET_NOTIFICATION', {
              type: 'success',
              message: 'Cadastro de paciente Efetuado com Sucesso',
            });
            this.$store.commit(
              'SET_INFOS_PEOPLE',
              this.modelRegisterDefault.people
            );
            this.loading = false;
            this.setPatient(resp)
          })
          .catch((err) => {
            var messageError = '';
            switch (err.response.data.message) {
              case 'this email is already registered':
                messageError = 'Email já cadastrado.';
                break;
              case 'email already registered':
                messageError = 'Email já cadastrado';
                break;
              case 'this cpf is already registered':
                messageError = 'Cpf já cadastrado.';
                break;
              default:
                messageError = 'Não foi possivel cadastrar o paciente.';
            }
            this.$store.commit('SET_NOTIFICATION', {
              type: 'error',
              message: messageError,
            });
            this.loading = false;
          });

        // })
        // .catch((err) => {
        //   if (err.response.data.message == 'email already registered') {
        //     this.$store.commit('SET_NOTIFICATION', {
        //       type: 'danger',
        //       message: 'O email já esta cadastrado!',
        //     });
        //   } else {
        //     this.$store.commit('SET_NOTIFICATION', {
        //       type: 'danger',
        //       message: 'Não foi possivel cadastrar o usuário',
        //     });
        //   }
        // });
      } else {
        this.showAlert = true;
      }
    },
    getDependent() {
      this.patient_id = ls.get('patientId');
      this.$store.commit('SET_PATIENT_ID', this.patient_id);
      this.patient_id = ls.get('peopleId');
      let body = {
        dependent_id: this.patient_id,
      };
      this.$store.dispatch('getPatientDependent', body).then((resp) => {
        this.patients = resp;
      });
    },
    setPatient(patient) {
      ls.set('currentPatient', patient);
      this.$store.commit('SET_CURRENT_PATIENT', patient);
      this.$emit('nextStep');
    },
    // openLoginDialog() {
    //   this.$store.commit('SET_AUTH_MODAL', true);
    // },
    closeDependentDialog() {
      this.depedentModal = false;
    },
  },
};
</script>
