<template>
  <div class="vc-fadeIn vc-home vc-w-100 pa-2">
    <v-row class="vc-home-row-cards ma-0">
      <!-- <v-col cols="12" sm="7" class="text-center pa-0 mb-5">
        <v-btn Button historico de agendamentos
          @click="$router.push({ name: 'schedule-list' })"
          color="primary"
          class="ma-2 white--text"
        >
          <v-icon left dark>
            mdi-send
          </v-icon>
          <div class="vc-text-bold">histórico de agendamentos</div>
        </v-btn>
      </v-col> -->
      <v-col cols="12" sm="7" class="vc-home-column-cards pa-1">
        <card-consult
          :class="i === 6 ? '' : 'mb-5'"
          v-for="(scheduleType, i) in schedulesType"
          :key="`${i}${scheduleType.name}`"
          :scheduleType="scheduleType"
        />
      </v-col>
    </v-row>

    <div class="vc-home-svg">
      <img src="@/assets/images/svg-home-patient.svg" alt="" />
    </div>
    <Footer />
  </div>
</template>

<script>
import CardConsult from '@/components/home/CardConsult.vue';
import Footer from '@/components/Footer/Footer.vue';

export default {
  name: 'home',
  components: {
    CardConsult,
    Footer,
  },
  data() {
    return {
      schedulesType: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store
        .dispatch('getScheduleTypes')
        .then((resp) => {
          this.schedulesType = resp;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #d3e8ff; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #2e5f96; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: solid 3px #d3e8ff;
  // border: 3px solid orange; /* creates padding around scroll thumb */
}
</style>
