<template>
  <div class="vc-scheduling-content">
    <div class="vc-scheduling-wrapper">

      <stepper v-if="!isMobile"  :step="step" @setStep="setStep" />
      <v-row class="vc-scheduling-wrapper-content vc-w-100 vc-h-100 ma-0 pa-9">
        <!-- <img
          class="vc-scheduling-art"
          width="30%"
          src="@/assets/images/scheduling-svg-art.svg"
          alt=""
        /> -->

        <v-col cols="12" class="vc-scheduling-header pa-0">
          <v-btn
            :disabled="step === 1"
            @click="backStep"
            color="primary"
            normal
            fab
          >
            <v-icon size="35" color="white">mdi-arrow-left-bold</v-icon>
          </v-btn>

          <v-row class="ma-0 ml-2">
            <v-col class="pa-0" cols="12"
              ><div class="vc-font-18 vc-text-primary vc-text-extrabold">
                {{ pageHeader.title }}
              </div></v-col
            >
            <v-col class="pa-0" cols="12">
              <div class="vc-font-14 vc-text-darkGrey">
                {{ pageHeader.subtitle }}
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="step === 1" cols="12" class="pa-0">
          <div class="vc-text-darkGrey mt-1">Convênio</div>
          <v-autocomplete
            class="vc-auth-input"
            placeholder="Ex.: Particular"
            solo
            hide-details
            dense
          ></v-autocomplete>
        </v-col>

        <v-col v-if="step === 2" cols="12" class="pa-0">
          <div class="vc-text-darkGrey mt-1">Especialidade</div>
          <v-autocomplete
            class="vc-auth-input"
            placeholder="Busque aqui..."
            solo
            hide-details
            dense
          ></v-autocomplete>
        </v-col>

        <v-col v-if="step === 7" cols="12" class="pa-0">
          <div class="vc-text-darkGrey mt-1">Clínica</div>
          <v-autocomplete
            class="vc-auth-input"
            placeholder="Busque aqui..."
            solo
            hide-details
            dense
          ></v-autocomplete>
        </v-col>

        <v-col v-if="step === 3" cols="12" class="pa-0">
          <v-row class="ma-0 mb-4">
            <v-col cols="12" md="6" class="pa-0 pr-md-5 mt-4">
              <div class="vc-text-darkGrey">Profissional da saúde</div>
              <v-autocomplete
                v-model="consult.doctor_id"
                :items="doctors"
                @input="renderComponent()"
                item-text="people.name"
                item-value="id"
                class="vc-auth-input"
                placeholder="Ex.: Dr.Fulano"
                solo
                hide-details
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6" class="pa-0 mt-4">
              <v-row class="ma-0">
                <v-col cols="12" md="6" class="pa-0 pr-md-2">
                  <div class="vc-text-darkGrey">Data ínicio</div>
                  <v-menu
                    ref="menuDateFrom"
                    v-model="menuFromActive"
                    :close-on-content-click="false"
                    :return-value.sync="dateFrom"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFromFormatted"
                        label="Selecionar"
                        placeholder="Ex.: DD/MM/YYYY"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="vc-auth-input"
                        solo
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      v-model="dateFrom"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menuFromActive = false"
                      >
                        fechar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="confirmDateFrom(dateFrom)"
                      >
                        confirmar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6" class="pa-0 pt-3 pt-md-0 pl-md-2">
                  <div class="vc-text-darkGrey">Data fim</div>
                  <v-menu
                    ref="menuDateTo"
                    v-model="menuToActive"
                    :close-on-content-click="false"
                    :return-value.sync="dateTo"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateToFormatted"
                        label="Selecionar"
                        placeholder="Ex.: DD/MM/YYYY"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="vc-auth-input"
                        solo
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="pt-br"
                      v-model="dateTo"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menuToActive = false">
                        fechar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="confirmDateTo(dateTo)"
                      >
                        confirmar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="step === 4" cols="12" class="pa-0">
          <div class="vc-text-darkGrey mt-1">Paciente</div>
          <v-autocomplete
            class="vc-auth-input"
            placeholder="Busque aqui..."
            solo
            hide-details
            dense
          ></v-autocomplete>
        </v-col>

        <v-col v-if="step === 1" cols="12" class="vc-scheduling-content-box">
          <form-convenant
            :company_id="currentCompany.id"
            @nextStep="nextStep"
          />
        </v-col>

        <v-col
          v-else-if="step === 2"
          cols="12"
          class="vc-scheduling-content-box"
        >
          <form-specialty
            @nextStep="nextStep"
            :company_id="currentCompany.id"
          />
        </v-col>

        <v-col
          v-else-if="step === 7"
          cols="12"
          class="vc-scheduling-content-box"
        >
          <form-clinic @nextStep="nextStep" />
        </v-col>

        <v-col
          v-else-if="step === 3"
          cols="12"
          class="vc-scheduling-content-box"
        >
          <form-date-hour :consult="consult" @nextStep="nextStep" :key="componentKey" />
        </v-col>

        <v-col
          v-else-if="step === 4"
          cols="12"
          class="vc-scheduling-content-box"
        >
          <form-patient
            :company_id="currentCompany.id"
            :currentPatientId="currentPatientId"
            @nextStep="nextStep"
          />
        </v-col>

        <v-col
          v-else-if="step === 5"
          cols="12"
          class="vc-scheduling-content-box"
        >
          <form-confirmation @nextStep="nextStep" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ls from 'local-storage';
import Stepper from '@/components/Stepper/Stepper';
import FormConvenant from '@/components/scheduling/forms/FormConvenant';
import FormSpecialty from '@/components/scheduling/forms/FormSpecialty';
import FormClinic from '@/components/scheduling/forms/FormClinic';
import FormDateHour from '@/components/scheduling/forms/FormDateHour';
import FormPatient from '@/components/scheduling/forms/FormPatient';
import FormConfirmation from '@/components/scheduling/forms/FormConfirmation';
import { format, addMonths, addDays } from 'date-fns';
// import Card from '@/components/scheduling/Card';

export default {
  name: 'scheduling',
  components: {
    Stepper,
    FormConvenant,
    FormSpecialty,
    FormClinic,
    FormDateHour,
    FormPatient,
    FormConfirmation,
    // Card,
  },
  data() {
    return {
      step: 1,
      componentKey: 0,
      menuFromActive: false,
      menuToActive: false,
      dateFromFormatted: '',
      dateFrom: '',
      dateToFormatted: '',
      dateTo: '',
      doctors: [],
      consult: {
        company_id: '',
        date_from: '',
        date_to: '',
        type_schedule_id: '',
        doctor_id: '',
        health_insurance_id: '',
        specialist_id: '',
        empty: 'true',
      },
      pageHeader: {
        stepId: 1,
        title: 'Convênio',
        subtitle: 'Selecione o convênio',
      },
      pageHeaders: [
        { stepId: 1, title: 'Convênio', subtitle: 'Selecione o convênio' },
        {
          stepId: 2,
          title: 'Especialidade',
          subtitle: 'Selecione a especialidade',
        },
        { stepId: 7, title: 'Clínica', subtitle: 'Selecione a clínica' },
        {
          stepId: 3,
          title: 'Data e Hora',
          subtitle: 'Selecione a data e hora',
        },
        { stepId: 4, title: 'Paciente', subtitle: 'Selecione o paciente' },
        {
          stepId: 5,
          title: 'Confirmação',
          subtitle: 'Confirme seu agendamento',
        },
      ],
    };
  },
  computed: {
    isMobile() {
      if(window.innerWidth<=913){

        return true;
      }else{
        return false
      }
    },
    currentCompany() {
      return this.$store.getters.currentCompany;
    },
    currentConvenant() {
      return this.$store.getters.currentConvenant;
    },
    currentSpecialty() {
      return this.$store.getters.currentSpecialty;
    },
    currentPatientId() {
      return this.$store.getters.currentPatientId;
    },
  },
  watch: {
    step() {
      this.setPageTitle();
    },
    currentConvenant() {
      this.consult.health_insurance_id = this.currentConvenant.id;
    },
    currentSpecialty() {
      this.consult.specialist_id = this.currentSpecialty.id;
      this.getAllDoctors();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (ls.get('currentCompany')) {
        this.$store.commit('SET_CURRENT_COMPANY', ls.get('currentCompany'));
      }
      if (ls.get('currentScheduleType')) {
        this.$store.commit('SET_SCHEDULE_TYPE', ls.get('currentScheduleType'));
        this.consult.schedule_type_id = ls.get('currentScheduleType');
      }
      if (ls.get('currentConvenant')) {
        this.$store.commit('SET_CURRENT_CONVENANT', ls.get('currentConvenant'));
      }
      if (ls.get('currentSpecialty')) {
        this.$store.commit('SET_CURRENT_SPECIALTY', ls.get('currentSpecialty'));
      }
      if (ls.get('currentScheduleCompany')) {
        this.$store.commit(
          'SET_CURRENT_SCHEDULE_COMPANY',
          ls.get('currentScheduleCompany')
        );
      }
      if (ls.get('currentSchedule')) {
        this.$store.commit('SET_CURRENT_SCHEDULE', ls.get('currentSchedule'));
      }
      if (ls.get('currentDoctor')) {
        this.$store.commit('SET_CURRENT_DOCTOR', ls.get('currentDoctor'));
      }
      if (ls.get('currentPatient')) {
        this.$store.commit('SET_CURRENT_PATIENT', ls.get('currentPatient'));
      }
      this.setConsultInitialValues();
    },
    renderComponent(){
       this.componentKey += 1;
    },
    getAllDoctors() {
      this.doctors = [];
      let Convenant = ls.get('currentConvenant');
      let Specialty = ls.get('currentSpecialty');
      Convenant.doctors.map((item) => {
        item.doctor_specialist_companies.map((item_doctor_specialist) => {
          if (item_doctor_specialist.id == Specialty.id) {
            this.doctors.push(item);
          }
        });
      });
      Convenant = '';
      Specialty = '';

      // let unshiftDoctors = {
      //   id: null,
      //   people: {
      //     name: 'Todos',
      //   },
      // };
      // this.doctors.unshift(unshiftDoctors);
    },
    nextStep() {
      this.step = this.step + 1;
    },
    backStep() {
      this.step = this.step - 1;
    },
    setStep(val) {
      this.step = val;
    },
    setPageTitle() {
      this.pageHeaders.map((item) => {
        if (item.stepId === this.step) {
          this.pageHeader.stepId = item.stepId;
          this.pageHeader.title = item.title;
          this.pageHeader.subtitle = item.subtitle;
          return 0;
        }
      });
    },
    setConsultInitialValues() {
      this.consult.company_id = this.currentCompany.id;
      let date_from = new Date();
      let date_to = addMonths(date_from, 1);
      this.consult.date_from = format(date_from, 'yyyy-MM-dd');
      this.consult.date_to = format(date_to, 'yyyy-MM-dd');
    },
    confirmDateFrom(date) {
      this.$refs.menuDateFrom.save(this.date);
      this.consult.date_from = date;
      this.dateFromFormatted = new Date(date);
      this.dateFromFormatted = addDays(this.dateFromFormatted, 1);
      this.dateFromFormatted = format(this.dateFromFormatted, 'dd/MM/yyyy');
    },
    confirmDateTo(date) {
      this.$refs.menuDateTo.save(this.date);
      this.consult.date_to = date;
      this.dateToFormatted = new Date(date);
      this.dateToFormatted = addDays(this.dateToFormatted, 1);
      this.dateToFormatted = format(this.dateToFormatted, 'dd/MM/yyyy');
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f2f2f2; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #2e5f96; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: solid 3px #f2f2f2;
  // border: 3px solid orange; /* creates padding around scroll thumb */
}
</style>
